<template>
  <div>
    <el-card class="box-card">
      <el-dialog
        class="AddDialog"
        title="查看"
        :visible.sync="dialogVisible"
        width="800px"
        hegiht="1200px"
        :close-on-click-modal="false"
        @close="close"
      >
        <page-table
          ref="dataTable"
          :data="userList"
          @changeCurrentPage="changeCurrent"
        >
          <el-table-column label="序号" align="center">
            <template slot-scope="scope">
              <span>{{
                (page.currentPage - 1) * page.pageSize + scope.$index + 1
              }}</span>
            </template>
          </el-table-column>

          <el-table-column
            prop="uid"
            label="ID"
            align="center"
          ></el-table-column>

          <el-table-column
            prop="username"
            label="用户名"
            align="center"
          ></el-table-column>
        </page-table>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import moment from "moment";
import { pkreadall } from "@/request/api";
import pageTable from "@/components/pageTable.vue";
export default {
  name: "showFen",
  components: { pageTable },
  data() {
    return {
      userList: [],
      dialogVisible: false,
      day: "",
      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
    };
  },
  filters: {
    Time(e) {
      //处理时间
      return moment(parseInt(e)).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.getUserList();
    },
    show(row) {
      console.log(row);
      this.dialogVisible = true;
      this.day = row.day;
      this.getUserList();
    },
    close() {
      this.dialogVisible = false;
    },
    // 获取用户列表
    getUserList() {
      let params = {
        token: sessionStorage.getItem("token"),
        page: this.page.currentPage,
        limit: this.page.pageSize,
        day: this.day,
      };
      pkreadall(params).then((res) => {
        this.userList = res.data.data;
        this.page.total = res.data.count;
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
  },
};
</script>

<style>
.xian {
  margin-left: -75px !important;
}
</style>
