<template>
  <div class="user">
    <div class="biaoti">APP弹窗查看数据</div>
    <div class="block-quote">
      <el-form :inline="true">
        <el-form-item label="日期搜索" prop="time">
          <el-date-picker
            v-model="time"
            type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="用户ID" prop="username">
          <el-input
            style="width: 180px"
            v-model="uid"
            clearable
            placeholder="请输入用户id"
          ></el-input>
        </el-form-item>

        <el-form-item style="float: right">
          <!-- v-if="checkPermission('usersearch')" -->
          <el-button type="primary" icon="el-icon-search" @click="serch"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <page-table
      ref="dataTable"
      :data="userList"
      @changeCurrentPage="changeCurrent"
    >
      <el-table-column prop="day" label="日期" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.day != null">{{
            parseTime(scope.row.day, "{y}-{m}-{d}")
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="android"
        label="安卓"
        align="center"
      ></el-table-column>

      <el-table-column prop="ios" label="IOS" align="center"></el-table-column>
      <el-table-column prop="all" label="点击次数" align="center">
        <template slot-scope="scope">
          <!-- <span @click="set(scope.row)">{{ scope.row.all }}</span>
           -->
          <el-link @click="showFen(scope.row)">{{ scope.row.all }}</el-link>
        </template>
      </el-table-column>
    </page-table>
    <!-- 新增编辑弹窗 -->
    <show-fen ref="showFen" />
  </div>
</template>

<script>
import { pkreadinfo } from "@/request/api";
// 按钮权限
// import { checkPermission } from "@/utils/permissions";
import { parseTime } from "@/utils";

import pageTable from "@/components/pageTable.vue";
import showFen from "./components/showFen.vue";

export default {
  name: "user",
  components: {
    pageTable,
    showFen,
  },
  data() {
    return {
      userList: [], // 用户列表
      uid: "", // 用户id
      time: "",
      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
    };
  },
  watch: {
    time(newVal) {
      if (newVal == null) {
        this.time = [];
      }
    },
  },
  created() {
    this.getUserList(); //获取用户列表
  },
  mounted() {},
  computed: {},
  methods: {
    parseTime,
    // checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.getUserList();
    },
    serch() {
      // console.log(this.userList);
      // console.log(this.uid);
      // console.log(this.time);
      this.getUserList(); //获取用户列表
    },
    getUserList() {
      // 获取列表
      let token = sessionStorage.getItem("token");
      this.token = token;
      let params = {};
      if (this.time[0] && this.time[1]) {
        params = {
          page: this.page.currentPage,
          limit: this.page.pageSize,
          token: sessionStorage.getItem("token"),
          uid: this.uid,

          time1: this.time[0] + "00:00:00",
          time2: this.time[1] + "00:00:00",
        };
      } else {
        params = {
          page: this.page.currentPage,
          limit: this.page.pageSize,
          token: sessionStorage.getItem("token"),
          uid: this.uid,
        };
      }
      console.log(params);
      pkreadinfo(params).then((res) => {
        // 渲染数据
        this.userList = res.data.data;
        console.log(this.userList);
        this.page.total = res.data.count;
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
    showFen(row) {
      this.$refs.showFen.show(JSON.parse(JSON.stringify(row)));
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: collapse;

  text-align: center;
}
table td,
table th {
  border: 1px solid #cad9ea;
  color: #666;
  height: 30px;
  font-size: 12px;
}
table thead th {
  background-color: #cce8eb;
  width: 100px;
}
table tr:nth-child(odd) {
  background: #fff;
}
table tr:nth-child(even) {
  background: #f5fafa;
}
</style>
